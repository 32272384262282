<script setup lang="ts">
import type { Contact } from '@/types/contact';

const props = defineProps({
	contact: {
		type: Object as PropType<Contact>,
		default: () => {},
		required: true,
	},
	isLoading: {
		type: Boolean,
		required: true,
		default: false,
	},
	isSelect: {
		type: Boolean,
		default: false,
	},
	hideCheckbox: {
		type: Boolean,
		default: false,
	},
	isAtLimit: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits<{
	(e: 'onChange', value: boolean): void,
	(e: 'onRemove', id: number): void,
}>();

const contactStore = useContactStore();
const notificationStore = useNotificationStore();

const isChecked = ref<boolean>(props.isSelect);
const checkboxRef = ref();

const isDisabled = computed(() => props.contact.status !== CONTACT_STATUS.UNINVITED);
const inviteLimitNumber = computed(() => Number(contactStore.invitationLimit));

const contactStatusColor = computed(() => {
	switch (props.contact.status) {
		case CONTACT_STATUS.UNINVITED:
			return CHIP_COLOR.GRAY;
		case CONTACT_STATUS.INVITED:
			return CHIP_COLOR.PRIMARY;
		case CONTACT_STATUS.ACCEPTED:
			return CHIP_COLOR.SUCCESS;
		case CONTACT_STATUS.DECLINED:
			return CHIP_COLOR.ERROR;
		default:
			return CHIP_COLOR.GRAY;
	}
});

function handleOnChange(isSelect: boolean) {
	emit('onChange', isSelect);
}

function handleOnClick() {
	if (isDisabled.value) {
		return;
	}
	isChecked.value = !isChecked.value;
}

watch(() => props.isAtLimit, () => {
	isChecked.value = false;
	notificationStore.showErrorNotification(`Reached maximum limit of ${inviteLimitNumber.value} invites.`);
});
</script>

<template>
  <tr class="contact-order text-sm text-medium">
    <td>
      <div
        :class="[
          'contact-header',
          {
            '-disabled': isDisabled
          }
        ]"
      >
        <BaseCheckbox
          v-if="!hideCheckbox"
          :id="`contact-id-${contact.id}-2`"
          ref="checkboxRef"
          v-model="isChecked"
          :name="`contact-${contact.id}-2`"
          :disabled="isDisabled"
          @on-change="handleOnChange"
        />
        <AvatarLink
          class="avatar-link"
          :image-url="contact?.avatar"
          :alt="contact?.name || `contact avatar`"
          :size="AvatarSizes[2]"
          is-preview-mode
          @click="handleOnClick"
        >
          <template #content>
            <div class="contact-info-container">
              <p
                class="contact-name text-medium text-wrap"
              >
                {{ contact?.name }}
              </p>
              <p
                class="contact-email text-regular text-wrap"
              >
                {{ contact?.email }}
              </p>
            </div>
          </template>
        </AvatarLink>
      </div>
    </td>
    <td class="text-left no-padding-right">
      <template v-if="contact?.status">
        <BaseChip
          class="contact-status text-xs"
          :color="contactStatusColor"
        >
          <p class="no-wrap">
            {{ contact.status }}
          </p>
        </BaseChip>
      </template>
      <template v-else>
        <BaseButton
          class="remove-contact-btn"
          size="sm"
          variant="subtle"
          color="gray"
          icon-only
          :disabled="isLoading"
          @click="$emit('onRemove', Number(contact.id))"
        >
          <TrashIcon />
        </BaseButton>
      </template>
    </td>
  </tr>
</template>

<style scoped lang="scss">
.contact-order {
  .contact-header {
    display: flex;
    gap: spacings-get(3);
    padding-left: spacings-get(1);

    :deep(.checkmark) {
      width: spacings-get(5);
      height: spacings-get(5);
    }

    .avatar-link {
      display: flex;
      gap: spacings-get(3);
      cursor: pointer;
    }

    &.-disabled {
      .avatar-link {
        cursor: not-allowed;
      }
    }
  }

  .contact-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .contact-name {
      display: block;
      color: colors-get(gray, 900);
    }

    .contact-email {
      display: block;
      color: colors-get(gray, 600);
    }

    @include media-query-max(mobile) {
      .contact-name,
      .contact-email {
        max-width: rem(220);
        padding-right: spacings-get(2);
      }
    }
  }

  .contact-status {
    display: flex;
    margin: auto;
  }

  .no-padding-right {
    padding-right: 0 !important;
  }

  .remove-contact-btn {
    display: flex;
    margin: auto;
  }
}
</style>
